import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";
import Section from "../components/atoms/section";
import Page from "../components/atoms/page";
import Main from "../components/atoms/main";
import {Link} from "gatsby";
import {link_order} from "../helpers/constants";

function PizzaCalculator() {

    const title = 'Pizza Calculator';
    return (
        <Layout useMain={true}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />
            <Page title={title} subtitle={'Plan the Ultimate Feast'}>
                <p>Serving pizza has always been a popular choice, but how many should you order? Make sure no one goes
                    hungry! Use the pizza calculator to help you plan the ultimate feast. To get started, enter in the
                    number of hungry mouths in the fields below then click “calculate”. For a crowd over 30 people,
                    please contact your local Pizza Guys store to get a more accurate recommendation.</p>

                <>Number of Adults</>
                <>Number of Children</>


            </Page>

            <Section color={'bg-red-800 p-4'} className={''}>
                <div className={'text-center'}>
                    <Link className={'bg-red-700 p-4 pr-10 pl-10 rounded text-white font-bold text-2xl uppercase'}
                          to={link_order}>Start Your Order</Link>
                </div>
            </Section>
        </Layout>
    );
}

export default PizzaCalculator;
